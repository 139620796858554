// Base File
html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .5px;
  overflow-x: hidden;

  @media(max-width: 767px) {
    font-size: 14px;
  }
}

a {
  text-decoration: none;

  &:hover {
    background-position: 0;
    text-decoration: none;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.desktop-show {
  @media(max-width: 767px) {
    display: none;
  }
}

.mobile-show {
  display: none;

  @media(max-width: 767px) {
    display: block;
  }
}

.fa,
.fab,
.fal,
.far,
.fas {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.section {
  padding: 50px 0;
}

.text-green {
  color: $primary;
}

.row-reverse {
  @media(max-width: 767px) {
    flex-direction: column-reverse;
  }
}