// Typography

h1 {
  font-size: 40px;

  @media(max-width: 767px) {
    font-size: 32px;
  }
}

h2 {
  font-size: 28px;

  @media(max-width: 767px) {
    font-size: 22px;
  }
}

h3 {
  font-size: 26px;

  @media(max-width: 767px) {
    font-size: 18px;
  }
}

h5 {
  @media(max-width: 767px) {
    font-size: 16px;
  }
}