//  Button

.btn {
  font-size: 18px;
  box-shadow: none !important;
  border-radius: 5px;
  padding: 5px 20px;

  @media(max-width: 767px) {
    font-size: 16px;
  }

  &.btn-outline-primary {
    color: $primary;
    border-color: $primary;
    border: 1.5px solid $primary;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  &.bg-green {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $secondary;
    }
  }
}