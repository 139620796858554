/**
 * SETTINGS
 *  - Color Variable
 */

//  Color Variable
$black: #000;
$white: #FFF;

$primary: #9BCA46;
$secondary: #136290;