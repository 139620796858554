.banner {
  height: 500px;
  position: relative;
  overflow: hidden;

  @media(max-width: 767px) {
    height: 300px;
  }

  &:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    z-index: 9;
  }

  img {
    width: 100%;

    @media(max-width: 767px) {
      height: 300px;
      object-fit: cover;
    }
  }

  .home-slider {
    height: auto;

    button {
      display: none !important;
    }
  }

  .banner-content {
    color: white;
    position: relative;
    text-align: center;
    z-index: 99;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 015px;

    h1 {
      font-size: 60px;

      @media(max-width: 767px) {
        font-size: 40px;
      }
    }

    p {
      font-size: 25px;

      @media(max-width: 767px) {
        font-size: 20px;
      }
    }
  }
}

.about-wrapper {
  padding-bottom: 0;

  img {
    @media(max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}

.product-wrapper {
  text-align: center;

  .product-name {
    color: $secondary;

    &:hover {
      color: $primary;
    }
  }

  .product-wrap {
    margin-bottom: 40px;
  }

  .product-image {
    height: 290px;
    width: 290px;
    margin: 0 auto;
    background: linear-gradient(to right, $primary, $secondary);
    padding: 7px;
    border-radius: 50%;
    margin-bottom: 20px;
    transition: transform .3s;
    cursor: pointer;

    @media(max-width: 991px)and(min-width: 768px) {
      height: 200px;
      width: 200px;
    }


    &:hover {
      transform: scale(1.1);
    }



    img {
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// Feature section
.feature-wrapper {
  background: url("../assets/images/home/feature-bg.png") center center repeat;
  background-size: contain;
  position: relative;
  background-color: #F7F8FC !important;

  img {
    filter: brightness(0) saturate(100%) invert(95%) sepia(9%) saturate(2998%) hue-rotate(23deg) brightness(83%) contrast(89%);
  }

  .section-title {
    &::before {
      top: -20px;
    }

    &::after {
      top: -10px;
    }
  }
}

.section-title {
  position: relative;
  text-align: center;
  padding-bottom: 50px;

  &::before,
  &::after {
    position: absolute;
    content: "";
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
  }

  &::before {
    width: 60px;
    top: 45px;
    background: $primary;
  }

  &::after {
    width: 90px;
    top: 55px;
    background: $secondary;
  }

  h2 {
    text-transform: capitalize;
  }
}