//header
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}

.header {
  .navbar {
    background-color: $white;

    .nav-item {
      &:not(:last-child) {
        @media(max-width: 991px) {
          border-bottom: 1px solid lightgray;
        }
      }
    }

    .nav-link {
      color: #1C630D;
      text-transform: uppercase;
      font-size: 16px;
      position: relative;
      font-weight: 500;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 100%;
        left: 0;
        background-color: $secondary;
        pointer-events: none;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
      }

      &::before {
        transform-origin: 100% 50%;
      }

      &::after {
        top: calc(100% + 4px);
        transform-origin: 0% 50%;
      }

      &:hover {
        color: $secondary;

        &::before,
        &::after {
          transform: scale3d(1, 1, 1);
          transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
        }

        &::before {
          transform-origin: 0% 50%;
        }

        &::after {
          transform-origin: 100% 50%;
        }
      }
    }

    .navbar-toggler {
      &:focus {
        box-shadow: none;
      }

      .navbar-toggler-icon {
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }
  }

  .navbar-brand {
    width: 430px;

    @media(max-width: 1199px) {
      width: 300px;
    }

    @media(max-width: 991px) {
      width: 300px;
    }

    @media(max-width: 575px) {
      width: 220px;
    }
  }


  .navbar-collapse {
    justify-content: flex-end;
  }

  .nav {
    transform: translateZ(0);
    transition: all 0.5s;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    animation: slide-down 0.7s;
    border-bottom: 1px solid $secondary;

    .navbar-brand {
      width: 200px;
    }
  }
}