// Breadcrumb
.dz-bnr-inr {
    min-height: 55px;
    background-size: cover;
    background-position: top;
    position: relative;
    z-index: 2;
    width: 100%;
    background-position: center;
    color: white;
    display: flex;
    align-items: center;
    font-size: 20px;

    &::before {
        content: '';
        background-color: $black;
        opacity: .7;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .dz-bnr-inr-entry {
        position: relative;

        .breadcrumb {
            margin-bottom: 0;

            a {
                color: $primary;
            }
        }
    }
}

// About Page
.about-us {
    .about-wrapper {
        a {
            color: $primary;
            font-weight: bold;

            // cursor: pointer;
            &:hover {
                color: $secondary;
            }
        }
    }

    .about-slider {
        img {
            width: 100%;
        }

        button {
            display: none !important;
        }
    }
}

// Contact page
.contact-page {
    .map-iframe {
        iframe {
            @media(max-width: 767px) {
                height: 300px;
            }
        }
    }

    .icon-bx-wraper {
        border: 1px solid $primary;
        height: 100%;
        border-radius: 20px;

        @media(max-width: 991px) {
            height: auto;
            margin-bottom: 20px;
        }

        i {
            color: $primary;
        }

        .icon-bx {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #c1c1c1;
            padding-bottom: 10px;

            .title {
                font-size: 20px;
                font-weight: 500;
                margin-left: 10px;
                margin-bottom: 0;
                color: $primary;
                font-weight: bold;
            }
        }

        p {
            margin-bottom: 0;
        }
    }
}

// Product Page
.product-page {
    .product-title {
        margin: 10px 0 20px;
    }

    .product-wrap {
        box-shadow: 0px 0px 7px 1px #dbdbdb;
        margin-bottom: 30px;
        padding: 15px;
        border-radius: 5px;
        position: relative;
        cursor: pointer;

        &:hover {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.7);
                width: 100%;
                transition: .5s ease;
                color: white;
                padding: 20px;
                text-align: center;
            }

            .product-detail {
                display: inline-block;
                width: 100%;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .product-image {
        width: 100%;
        height: 320px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .product-name {
        color: white;
        margin-bottom: 10px;
        font-size: 25px;
        text-align: center;
    }

    .product-detail {
        display: none;
    }
}

// Product Detail Page
.product-detail-page {
    .product-image {
        margin-bottom: 20px;
    }

    .detail-wrap {
        padding: 15px;
        box-shadow: 0 0 10px rgba(204, 204, 204, 0.67);
        border: 1px solid #ccc;
        border-color: rgba(0, 0, 0, 0.13);
    }

    .proDtlsBtn {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
    }
}

.product-table {
    table {
        tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            td {
                padding: 12px 10px 12px 15px;

                &:first-child {
                    padding-left: 0px;
                    min-width: 150px;
                    max-width: 200px;
                    width: 150px;
                }
            }
        }
    }
}

// Application page
.application-wrap {
    padding: 20px 15px;
    box-shadow: 0 0 10px rgba(204, 204, 204, 0.67);
    border: 1px solid #ccc;
    border-color: rgba(0, 0, 0, 0.13);
    padding: 50px;
    border-radius: 10px;
    // color: black;
    // background-image: linear-gradient(to right, $primary, $secondary);

    li {

        background: linear-gradient(to right, $primary, $secondary);
        // border: 1px dashed $primary;
        padding: 5px;
        margin-bottom: 30px;

        .app-content {
            background-color: white;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
        }

        @media(max-width: 480px) {
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .app-image {
        margin-left: 15px;

        img {
            height: 160px;
            width: 160px;
            border-radius: 50%;
            object-fit: cover;
            min-width: 160px;
        }
    }
}

// Process page
.process-bg {
    padding: 50px;
    border-radius: 20px;
    color: white;
    background-image: linear-gradient(to right, $primary, $secondary);

    @media(max-width: 767px) {
        padding: 30px 15px;
    }

    .process-image {
        text-align: center;
        margin-bottom: 30px;

        img {
            width: 400px;
            height: 300px;
            object-fit: cover;

            @media(max-width: 767px) {
                width: 100%;
            }
        }
    }

    h4 {
        font-size: 40px;
    }
}