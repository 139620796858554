.footer-box {
    // background-image: radial-gradient(ellipse at center, rgba($secondary, 0.57) 0%, rgba(0, 0, 0, 0.87) 100%), url("../assets/images/footer_background.jpg");
    background-image: radial-gradient(ellipse at center, rgba($black,
                0.57) 0%, rgba(0, 0, 0, 0.87) 100%),
        url("../assets/images/footer_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    font-size: 14px;

    .footer-logo {
        padding-bottom: 40px;
    }

    .footer-content {
        h3 {
            color: $primary;
        }

        ul {
            li {
                margin-bottom: 10px;
            }
        }

        a {
            color: $white;

            &:hover {
                color: $primary;
            }
        }
    }

    .copyright-main {
        background-color: #c2ee73;
        border-top: 2px solid $black;
        color: $black;
        text-align: center;
        padding-top: 10px;
        margin-top: 40px;
        padding-bottom: 10px;

        .copy-title {
            margin-bottom: 0;
            font-size: 14px;

            a {
                color: black;
                font-weight: bold;
            }
        }
    }

    .socials-box {
        display: flex;
        align-items: center;
        margin: 1px 0px;
        padding: 4px;

        a {
            margin-right: 10px;
            display: inline-block;

            &:hover {
                .social-circle-border {
                    color: $primary;
                }
            }
        }

        .social-circle-border {
            font-size: 20px;
            line-height: 23px;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background-color: transparent;
            color: $white;
            text-align: center;
        }
    }
}